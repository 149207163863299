/* eslint-disable fp/no-mutation */
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import i18next from 'i18next'
import {textEllipsis} from 'lib/utils/utility'
import {HTTPS_REQUEST_TYPE} from 'lib/services/httpRequest'
import {setProfileDetails} from 'lib/services/profileDetailsAPI'
import {batteryProfileSelector, setBpDetailsValue, setBpValue} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {setLoaderValue} from 'lib/store/slices/loaderSlice'
import {
  ACTION_TYPE,
  PAGE_NAME,
  PROFILE_STATUS,
  PROFILE_TYPE,
  STATUS,
  TOAST_TYPE,
  TOGGLE_SWITCH_STATUS,
  STORM_GUARD_STATUS,
  PROFILE_TYPE_NEM3,
} from 'lib/utils/constants'
import {
  hasSystemCard,
  hasEncharge,
  isSiteGridAgnostic,
  showSavingsMode,
  isEvseSite,
  isDTSupportedSite,
  hasHemsDevices,
  getDeviceType,
  isHemsSite,
  customScheduleEnabled,
  isCoExistingSite,
  IQGWScheduleSupportWithSavingsMode,
  isEvChargingModesSupported,
} from 'lib/utils/siteDetails'
import {isUserHasAccess, isDemoUserBlocked} from 'lib/utils/userDetails'
import Analytics from 'lib/utils/analytics'
import Icon from '../../../../utils/icon'
import {setBrValue} from '../../molecules/BatteryReserve/brSlice'
import {
  getDeviceProfileTranslation,
  getInfoDescription,
  getLoadingStatus,
  getModeTranslation,
  getRetryStatusForProfile,
} from '../../molecules/DeviceBehaviour/dbCore'
import {setDeviceUpdateDisable} from '../../molecules/DeviceBehaviour/dbSlice'
import {SelectProfileHelp} from '../../molecules/SelectProfileHelp'
import {getProfileCurrentDetails, updateProfileSliceValues} from '../../organisms/ProfileDetails/pdCore'
import {setPdValue} from '../../organisms/ProfileDetails/pdSlice'
import {cancelSystemProfileRequest, restrictUserToPerformAction} from '../../organisms/bpCommon'
import {
  isAnyRequestPendingForChargeBatteryFromGridWithIQGWSite,
  isAnyRequestPendingForDischargeBatteryToGrid,
  isThisProfileRequestPending,
} from '../../organisms/reqPending'
import {
  displayStatus,
  getActiveCharger,
  getDeviceBehaviourInfoDetails,
  getDevices,
  getErrorAlert,
  getToggleStatus,
  startTimer,
} from '../EvTopUpSchedule/evScheduleCore'
import {IntractiveButton} from '../IntractiveButton'
import {showToast} from '../Toast'
import {getThisProfileDetails, renderSavingAIPowerBeta} from './sysCore'
import './styles.scss'
import {navigateToSchedule} from 'lib/common'

/* *********************** *********************** *********************** *
 * System Profile
 * *********************** *********************** *********************** */

const SystemProfile = props => {
  const batteryProfile = useSelector(batteryProfileSelector)
  const profileDetails = useSelector(state => state.components.profileDetails)
  const {details} = useSelector(batteryProfileSelector)
  const devices = getDevices(details)
  const {deviceChanges} = useSelector(state => state.components.deviceUpdate[props.profile])
  let topTitle

  if (((isHemsSite() && customScheduleEnabled()) || isEvseSite()) && hasSystemCard()) {
    topTitle = i18next.t('bp204')
  } else {
    topTitle = i18next.t('bp15')
  }

  const caption = i18next.t('bp203')

  const dispatch = useDispatch()
  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const showLoader = obj => dispatch(setLoaderValue({...obj}))
  const setBpDetails = obj => dispatch(setBpValue({...obj}))
  const setBrSlice = obj => dispatch(setBrValue({...obj}))
  const setBatteryData = obj => dispatch(setBpDetailsValue(obj))
  const setPdSlice = obj => dispatch(setPdValue({...obj}))
  const setDeviceBehaviorDisable = obj => dispatch(setDeviceUpdateDisable(obj))

  const isDisabled = details.systemTask || details.requestedConfig.systemTask || details.requestedConfigMqtt.systemTask
  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  // System Profile :: Check edit button disabled condition
  const isEditDisabled = () => {
    if (
      isAnyRequestPendingForDischargeBatteryToGrid(details) ||
      isAnyRequestPendingForChargeBatteryFromGridWithIQGWSite(details) ||
      (!batteryProfile.loading &&
        restrictUserToPerformAction({
          details,
          inProgress: profileDetails.inProgress,
          checkUserAccess: false,
          showUserAccessToast: false,
          showDialog: false,
          closeThisDialog,
        }))
    ) {
      return true
    }

    return false
  }

  const showEditButton = () => {
    if (isEvseSite()) return true
    return !(hasEncharge() && !isSiteGridAgnostic()) || showSavingsMode() || hasHemsDevices()
  }

  // System Profile :: onClick edit button
  const onClickEditButton = () => {
    if (isDemoUserBlocked()) return

    Analytics.sendClickEvent('Edit_Profile', 'Profile')

    if (
      restrictUserToPerformAction({
        details,
        inProgress: profileDetails.inProgress,
        checkUserAccess: false,
        showUserAccessToast: false,
        showDialog,
        closeThisDialog,
      })
    ) {
      return
    }

    if (
      isAnyRequestPendingForDischargeBatteryToGrid(details) ||
      isAnyRequestPendingForChargeBatteryFromGridWithIQGWSite(details)
    ) {
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp407'),
        autoClose: 3000,
      })
      return
    }

    setBpDetails({subPage: [...batteryProfile.subPage, PAGE_NAME.EDIT_PROFILE]})

    setBrSlice({
      reserveProfile: {},
    })
  }

  // EV Charger :: onClick info popup bottom drawer
  const onClickEvChargerInfo = (profile, deviceObj) => {
    showDialog(getDeviceBehaviourInfoDetails(profile, deviceObj, getInfoDescription, closeThisDialog))
  }

  // System Profile :: onClick cancel request
  const onClickCancelButton = obj => {
    Analytics.sendClickEvent('Cancel_Request_Profile', 'Profile')
    const {profile, ref} = obj

    if (isDisabled) {
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp257'),
        autoClose: 3000,
      })
      return
    }

    if (!isUserHasAccess()) {
      showLoader({show: false})
      return
    }

    ref.style.display = 'none'
    showLoader({show: true, noBg: true})

    cancelSystemProfileRequest({
      ref,
      profile,
      details,
      dispatch,
      showLoader,
      setBatteryData,
      setPdSlice,
      setDeviceBehaviorDisable,
    })
  }

  // Handle retry button click
  async function onClickRetry(obj) {
    const profile = obj.expandedProfile
    // Check User Access
    if (
      restrictUserToPerformAction({
        details,
        inProgress: profileDetails.inProgress,
        showDialog,
        closeThisDialog,
        enableRetry: true,
      })
    ) {
      return
    }

    const selectedCharger = devices?.find(device => device.uuid === obj.id)

    if (isCoExistingSite() || (isEvseSite() && !(isDTSupportedSite() || isHemsSite()))) {
      const activeCharger = getActiveCharger(obj, deviceChanges, devices)
      if (details?.stormGuardState !== STORM_GUARD_STATUS.ACTIVE && activeCharger && activeCharger.uuid !== obj.id) {
        showDialog(getErrorAlert(activeCharger, closeThisDialog))
        return
      }
    }

    const updateDevice = {
      uuid: obj.id,
      deviceType: isCoExistingSite() ? selectedCharger.deviceType : getDeviceType(),
      enable: details?.stormGuardState === STORM_GUARD_STATUS.ACTIVE ? false : !selectedCharger.enable,
      loading: false,
    }
    if (profile === PROFILE_TYPE.SELF_CONSUMPTION)
      updateDevice.profileConfig = selectedCharger.profileConfig ? selectedCharger.profileConfig : 'full'

    const deviceSetObj = {...obj}
    deviceSetObj.devices = [updateDevice]
    await setProfileDetails(deviceSetObj)
    updateProfileSliceValues({source: HTTPS_REQUEST_TYPE.MQTT, dispatch, data: details})
  }

  /* *********************** *********************** *********************** *
   * Render Function
   * *********************** *********************** *********************** */

  function renderEvCharger(obj) {
    const {id, title, status, profile, enable, retry, enableStatus, deviceProfile, deviceType, chargeMode} = obj
    let lastActiveProfile = null
    const isProfileActive = true
    const {inProgress} = profileDetails
    const loading = getLoadingStatus(enableStatus, deviceChanges, id, false, isProfileActive, inProgress)
    if (loading) startTimer(obj.id, dispatch)
    const toggleStatus = getToggleStatus(enable, id)
    let subTitle = null
    const tStatus = loading ? TOGGLE_SWITCH_STATUS.LOADING : toggleStatus
    //  retry state needs to be handled
    if (retry) {
      subTitle = i18next.t('bp230')
      if (enable) {
        lastActiveProfile = i18next.t(getDeviceProfileTranslation(deviceProfile))
      }
    }

    return (
      <div key={id} className="evs--ev-charger atom-design--t3"
        onClick={() => isEvChargingModesSupported() && navigateToSchedule({uuid: id, deviceName: title, profile})}
      >
        <div className="com__ad__icon">
          <Icon src="evCharger" />
        </div>

        <div className="com__ad__details">
          <div className={`com__ad--title ${isEvChargingModesSupported() ? 'com__ad--charger-title' : ''}`}>
            {isEvChargingModesSupported() ? (
              <div className="com__ad--charger-subtitle">
                <span>{textEllipsis(title, 17)}</span>
                <span className="com__ad--charger-mode">{i18next.t(getModeTranslation(chargeMode))}</span>
              </div>
            ) : (
              <>
                <span>{textEllipsis(title, 17)}</span>
                <span
                  className="com__ad--info"
                  onClick={() => onClickEvChargerInfo(profile, {deviceType})}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex="0">
                  <Icon src="info" />
                </span>
              </>
            )}
          </div>

          {subTitle && !isEvChargingModesSupported() && <div className="com__ad--sub-title">{subTitle}</div>}
          {retry && enable && !isEvChargingModesSupported() ? (
            <div className="com__ad--title db__value">{lastActiveProfile}</div>
          ) : null}
        </div>

        {isEvChargingModesSupported() ? (
          <div className="com__ad__action--button" role="button" tabIndex="0" onKeyDown={() => {}}>
            <Icon src="leftArrow" className="arrow-right" />
          </div>
        ) : (
          !(profileDetails.openCard !== profile && retry) && (
            <div
              className={classNames('com__ad__action', {
                'com__ad__action--enabled': status === STATUS.ENABLED,
                'com__ad__action--disabled': status === STATUS.DISABLED,
                'com__ad__action--loading': tStatus === TOGGLE_SWITCH_STATUS.LOADING,
              })}>
              {retry ? (
                <div
                  className="db__retry"
                  role="button"
                  onClick={() => onClickRetry({id, profile})}
                  onKeyDown={() => {}}
                  tabIndex="0">
                  <Icon src="retry" />
                </div>
              ) : (
                displayStatus(tStatus)
              )}
            </div>
          )
        )}
      </div>
    )
  }

  function renderEvChargers(profile) {
    return (
      <div className="evs--ev-chargers">
        {devices.map(device =>
          renderEvCharger({
            id: device.uuid,
            title: device.deviceName,
            status: device.enable ? STATUS.ENABLED : STATUS.DISABLED,
            profile,
            deviceProfile: device.profile,
            enable: device.enable,
            retry: getRetryStatusForProfile(device.status, deviceChanges, device.uuid),
            enableStatus: device.status,
            deviceType: device.deviceType,
            chargeMode: device.chargeMode,
          })
        )}
      </div>
    )
  }

  function renderProfile(profile) {
    const {title, showProfile, description} = getThisProfileDetails(profile)
    const spDetails = getProfileCurrentDetails(details, profile, profileDetails.inProgress, profileDetails.requestType)
    const spStatus = spDetails.type
    const spStatusSubType = spDetails.subType
    const spStatusText = spDetails.typeText
    const isActive = spStatus && spStatus.toLowerCase() === PROFILE_STATUS.ACTIVE
    const isProcessing =
      (spStatus && spStatus.toLowerCase() === PROFILE_STATUS.PROCESSING) ||
      (spStatusSubType && spStatusSubType === PROFILE_STATUS.PROCESSING)
    const isPending = spDetails.buttonActionType === ACTION_TYPE.CANCEL
    const showIcon = spStatus && (isActive || isProcessing || isPending)
    const iconSrc = isActive ? 'rightTick' : 'clockCircular'
    const canShowLoader = isProcessing || (!isPending && isThisProfileRequestPending(details, profile))

    return (
      showProfile && (
        <div
          id={`${isActive ? 'sp-profile-active' : 'sp-profile'}`}
          aria-label={`${isActive ? 'sp-profile-active' : 'sp-profile'}`}
          key={profile}
          className={classNames('sp__profile', {
            'sp__profile--active': isActive,
            'sp__profile--processing': isProcessing,
            'sp__profile--pending': isPending,
          })}>
          <div className="sp__profile--title atom-design--t3">
            {showIcon && (
              <div
                id={`${isActive ? 'sp-profile-active-icon' : ''}`}
                aria-label={`${isActive ? 'sp-profile-active-icon' : ''}`}
                className="com__ad__icon">
                <Icon src={iconSrc} />
              </div>
            )}

            <div className="com__ad__details">
              <div className="com__ad--title">
                <span>{title}</span>
                {renderSavingAIPowerBeta(profile)}
                <span className="com__ad--info">{spStatusText && <>({spStatusText})</>}</span>
              </div>
            </div>

            {canShowLoader && (
              <div className="com__ad__action">
                <Icon src="loaderV3" />
              </div>
            )}
          </div>

          <div className="sp__desc">{description}</div>

          {isPending && !isDisabled && (
            <IntractiveButton
              className="bp__action-button bp__ab--round-corner bp__ab--outline bp__ab--auto-width"
              type={isDisabled ? ACTION_TYPE.DISABLED : ACTION_TYPE.CANCEL}
              label={i18next.t('bp49')}
              disabled={false}
              loading={false}
              onClick={onClickCancelButton}
            />
          )}
          {isActive && hasSystemCard() && renderEvChargers(profile)}
        </div>
      )
    )
  }

  function renderSystemProfileDetails() {
    const profiles = IQGWScheduleSupportWithSavingsMode() ? _.values(PROFILE_TYPE_NEM3) : _.values(PROFILE_TYPE)

    return (
      <section className="bp__card-view">
        <div className="bp__card-view__wrapper">{profiles.map(profile => renderProfile(profile))}</div>
      </section>
    )
  }

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  return (
    <div className="bp__card--system-profile">
      {hasSystemCard() && <div className="pd__top--title">{topTitle}</div>}

      <SelectProfileHelp />

      <div className="bp__card--caption">
        <div>{caption}</div>
        {showEditButton() && (
          <IntractiveButton
            className="bp__action-button bp__ab--round-corner bp__ab--outline bp__ab--auto-width"
            type={isEditDisabled() ? ACTION_TYPE.DISABLED : ACTION_TYPE.EDIT}
            label={i18next.t('bp88')}
            disabled={false}
            loading={false}
            onClick={onClickEditButton}
          />
        )}
      </div>

      {renderSystemProfileDetails()}
    </div>
  )
}

SystemProfile.propTypes = {
  profile: PropTypes.string,
}

SystemProfile.defaultProps = {
  profile: '',
}

export default SystemProfile
