/* eslint-disable fp/no-mutation */
import _ from 'lodash'
import i18next from 'i18next'
import {getBatteryDetails} from 'lib/services/batteryDetailsAPI'
import {HTTPS_REQUEST_TYPE} from 'lib/services/httpRequest'
import {getSiteSettings} from 'lib/services/siteSettingsAPI'
import {getBatteryProfileDetails} from 'lib/store'
import {setBpDetailsValue, setBpValue} from 'lib/store/slices/batteryProfile'
import {setLoaderValue} from 'lib/store/slices/loaderSlice'
import {setSiteSetting, setSiteSettingDetails} from 'lib/store/slices/siteSettings'
import {FEATURE_KEYS, isFeatureSupported} from 'lib/utils/featureKeys'
import {getRequestFailedAlertDialog, showRequestChangeErrorDialog} from 'lib/utils/mqttAlertDetails'
import {COMPONENT_SHORT_HAND, PAGE_NAME, TOAST_TYPE, TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'
import {
  isGatewayNotReporting,
  isIQGWScheduleSupportedSite,
  isSiteGridTied,
  noActiveFeatureForBattery,
} from 'lib/utils/siteDetails'
import {minutesToString} from 'lib/utils/timeDetails'
import {isActivePage, omit} from 'lib/utils/utility'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {showToast} from '../../atoms/Toast'
import {getBatteryReserveValue} from '../../molecules/BatteryReserve/brCore'
import {setBrValue} from '../../molecules/BatteryReserve/brSlice'
import {getBatteryShutdownValue} from '../../molecules/BatteryShutdown/bsCore'
import {setBsValue} from '../../molecules/BatteryShutdown/bsSlice'
import {
  getCbfgToggleStatus,
  getChargeBeginTime,
  getChargeEndTime,
  getChargeFromGridScheduleSupported,
  getScheduleEnabled,
  showNewCFG,
} from '../../molecules/ChargeBatteryFromGrid/cbfgCore'
import {getCbfevToggleStatus} from '../../molecules/ChargeBatteryForEv/cbfevCore'
import {setCbfgValue} from '../../molecules/ChargeBatteryFromGrid/cbfgSlice'
import {setCbfevValue} from '../../molecules/ChargeBatteryForEv/cbfevSlice'
import {initMqtt} from '../mqttRequest'
import {setDbtgValue} from '../../molecules/DischargeBatteryToGrid/dbtgSlice'
import {
  getDbtgToggleStatus,
  getDischargeStartTime,
  getDischargeEndTime,
  getDischargeScheduleSupported,
  noScheduleForNEM3SelfConsExpOnlySite,
} from '../../molecules/DischargeBatteryToGrid/dbtgCore'

// Init Battery
export async function init({dispatch}) {
  const showLoader = val => dispatch(setLoaderValue({show: val}))
  const setBpDetails = obj => dispatch(setBpValue({...obj}))
  const setSiteSettingLoading = val => dispatch(setSiteSetting({loading: val}))
  const setSiteSettingsDetails = obj => dispatch(setSiteSettingDetails(obj))

  showLoader(true)

  // Get APIs response
  try {
    // Initiate MQTT connection
    const wsObj = {
      liveDebug: false,
      keepAlive: true,
      dispatch,
    }
    initMqtt({dispatch, wsObj})

    // Get APIs response for site settings and profile details
    const res = await Promise.all([getSiteSettings(), getBatteryDetails()])

    // Site Settings - Changes
    const resSiteSettings = res[0]
    setSiteSettingsDetails(resSiteSettings.data)

    const bpDetails = {}
    bpDetails.apiError = false
    bpDetails.invalidAccess = !isFeatureSupported(FEATURE_KEYS.HEMS_BATTERY_PROFILE)

    setSiteSettingLoading(false)

    // Battery Details - Changes
    const resBattery = res[1]
    updateBatterySliceValues({source: HTTPS_REQUEST_TYPE.API, dispatch, data: resBattery.data})

    // Check if battery page has no component to show
    if (noActiveFeatureForBattery()) {
      bpDetails.noActiveFeatureForBattery = true
    }

    bpDetails.loading = false
    setBpDetails(bpDetails)

    showLoader(false)
  } catch (e) {
    setBpDetails({apiError: true})
    showLoader(false)
    console.error('[Battery] init', e)
  }
}

// Update Values
export function updateBatterySliceValues({source, dispatch, profile, data}) {
  const setBatteryData = obj => dispatch(setBpDetailsValue(obj))
  const setCbfgSlice = obj => dispatch(setCbfgValue(obj))
  const setDbtgSlice = obj => dispatch(setDbtgValue(obj))
  const setCbfevSlice = obj => dispatch(setCbfevValue(obj))
  const setBrSlice = obj => dispatch(setBrValue(obj))
  const setBsSlice = obj => dispatch(setBsValue(obj))

  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  let reqKey = null

  // Charge Battery From Grid
  if (
    source === HTTPS_REQUEST_TYPE.API ||
    isResponseHasUpdatedChargeFromGrid(data) ||
    source === COMPONENT_SHORT_HAND.CHARGE_BATTERY_FROM_GRID
  ) {
    const chargeBeginTimeTxt = minutesToString(getChargeBeginTime(data))
    const chargeEndTimeTxt = minutesToString(getChargeEndTime(data))

    setCbfgSlice({
      toggleStatus: getCbfgToggleStatus(data),
      chargeBeginTime: {formatted12: chargeBeginTimeTxt},
      chargeEndTime: {formatted12: chargeEndTimeTxt},
      scheduleSupported: getChargeFromGridScheduleSupported(data),
    })

    // Charge from grid schedule enabled
    if (!showNewCFG() && !isSiteGridTied()) {
      setCbfgSlice({
        chargeFromGridScheduleEnabled: getScheduleEnabled(data),
      })
    }
  }

  // Discharge Battery To Grid
  if (
    source === HTTPS_REQUEST_TYPE.API ||
    isResponseHasUpdatedDischargeToGrid(data) ||
    source === COMPONENT_SHORT_HAND.DISCHARGE_BATTERY_TO_GRID
  ) {
    const dbtgStartTime = getDischargeStartTime(data)
    const dbtgEndTime = getDischargeEndTime(data)
    // Set null when optimise schedule is not available for NEM3SelfConsExpOnlySite
    const dischargeStartTimeTxt = noScheduleForNEM3SelfConsExpOnlySite(data, true)
      ? null
      : minutesToString(dbtgStartTime)
    const dischargeEndTimeTxt = noScheduleForNEM3SelfConsExpOnlySite(data, true) ? null : minutesToString(dbtgEndTime)
    // DTG update store
    setDbtgSlice({
      toggleStatus: getDbtgToggleStatus(data),
      startTime: {formatted12: dischargeStartTimeTxt},
      endTime: {formatted12: dischargeEndTimeTxt},
      scheduleSupported: getDischargeScheduleSupported(data),
    })
  }

  // Use Battery For EV
  if (source === 'cbfev') {
    setCbfevSlice({
      toggleStatus: getCbfevToggleStatus(data),
    })
  }

  // Battery Reserve
  if (source === HTTPS_REQUEST_TYPE.API || isResponseHasUpdatedBatteryBackupPercentage(data) || source === 'reserve') {
    setBrSlice({brReserve: getBatteryReserveValue({profile: profile || data?.profile, details: data})})
  }

  // Battery Shutdown
  if (source === HTTPS_REQUEST_TYPE.API || isResponseHasUpdatedVeryLowSoc(data) || source === 'shutdown') {
    setBsSlice({veryLowSoc: getBatteryShutdownValue(null, data)})
  }

  // Battery Details
  if (source === HTTPS_REQUEST_TYPE.API) {
    setBatteryData(data)
  }

  // Battery Details - mqtt/task
  if (source === HTTPS_REQUEST_TYPE.MQTT || source === HTTPS_REQUEST_TYPE.TASK) {
    const obj = {}
    const resData = omit(data, ['requestType', 'status', 'deviceDetails'])
    let toastType = null
    let toastMessage = null

    if (source === HTTPS_REQUEST_TYPE.MQTT) {
      reqKey = 'requestedConfigMqtt'
      obj.requestedConfig = {}
    } else if (source === HTTPS_REQUEST_TYPE.TASK) {
      reqKey = 'requestedConfig'
      obj.requestedConfigMqtt = {}
    }

    // Success
    if (data.status === 1) {
      toastType = TOAST_TYPE.SUCCESS
      toastMessage = i18next.t('bp69')
      obj[reqKey] = {...getBatteryProfileDetails()[reqKey], ...resData}

      if (isResponseHasUpdatedChargeFromGrid(resData)) {
        setCbfgSlice({requestType: null, loading: false})
        obj[reqKey] = omit(obj[reqKey], [
          'chargeFromGrid',
          'chargeBeginTime',
          'chargeEndTime',
          'acceptedItcDisclaimer',
          'cfgControl',
        ])
      }

      if (isResponseHasUpdatedDischargeToGrid(resData)) {
        const updatedBatteryDetails = {...getBatteryProfileDetails(), ...resData}
        let dbtgSliceObj = {}
        if (noScheduleForNEM3SelfConsExpOnlySite(updatedBatteryDetails)) {
          dbtgSliceObj = {
            startTime: {formatted12: null},
            endTime: {formatted12: null},
          }
        }
        setDbtgSlice({requestType: null, loading: false, ...dbtgSliceObj})
        obj[reqKey] = omit(obj[reqKey], ['dtgControl'])
      }

      if (isResponseHasUpdatedBatteryBackupPercentage(resData)) {
        setBrSlice({requestType: null, loading: false})
        obj[reqKey] = omit(obj[reqKey], ['batteryBackupPercentage'])
      }

      if (isResponseHasUpdatedVeryLowSoc(resData)) {
        setBsSlice({requestType: null, loading: false})
        obj[reqKey] = omit(obj[reqKey], ['veryLowSoc'])
      }

      // Update Battery Profile Slice
      obj[reqKey] = omit(obj[reqKey], ['systemTask'])
      setBatteryData({...resData, ...obj})
    }
    // Failed
    else if (data.status === 0) {
      toastType = TOAST_TYPE.ERROR
      toastMessage = i18next.t('bp71')
      obj[reqKey] = {...getBatteryProfileDetails()[reqKey], ...resData}

      const batteryDetails = getBatteryProfileDetails()
      if (isResponseHasUpdatedChargeFromGrid(resData)) {
        if (isIQGWScheduleSupportedSite()) {
          const resetCbfgSlice = {
            toggleStatus: batteryDetails?.chargeFromGrid ? TOGGLE_SWITCH_STATUS.ON : TOGGLE_SWITCH_STATUS.OFF,
            chargeBeginTime: {formatted12: minutesToString(batteryDetails?.chargeBeginTime)},
            chargeEndTime: {formatted12: minutesToString(batteryDetails?.chargeEndTime)},
            chargeFromGridScheduleEnabled: batteryDetails?.chargeFromGridScheduleEnabled,
            scheduleSupported: batteryDetails?.cfgControl?.scheduleSupported,
            isCbfgInfoChanged: false,
          }
          setTimeout(() => setCbfgSlice({...resetCbfgSlice}), 1000)
        }

        setCbfgSlice({requestType: null})
        setTimeout(() => setCbfgSlice({loading: false}), 1000)
        obj[reqKey] = omit(obj[reqKey], [
          'chargeFromGrid',
          'chargeBeginTime',
          'chargeEndTime',
          'acceptedItcDisclaimer',
          'cfgControl',
          'chargeFromGridScheduleEnabled',
        ])
      }

      if (isResponseHasUpdatedDischargeToGrid(resData)) {
        const resetDbtgSlice = {
          toggleStatus: batteryDetails?.dtgControl?.enabled ? TOGGLE_SWITCH_STATUS.ON : TOGGLE_SWITCH_STATUS.OFF,
          startTime: {
            formatted12: noScheduleForNEM3SelfConsExpOnlySite(batteryDetails)
              ? null
              : minutesToString(batteryDetails?.dtgControl?.startTime),
          },
          endTime: {
            formatted12: noScheduleForNEM3SelfConsExpOnlySite(batteryDetails)
              ? null
              : minutesToString(batteryDetails?.dtgControl?.endTime),
          },
          scheduleSupported: batteryDetails?.dtgControl?.scheduleSupported,
          isDbtgInfoChanged: false,
        }
        setDbtgSlice({requestType: null})
        setTimeout(() => setDbtgSlice({loading: false, ...resetDbtgSlice}), 1000)
        obj[reqKey] = omit(obj[reqKey], ['dtgControl'])
      }

      if (isResponseHasUpdatedBatteryBackupPercentage(resData)) {
        setBrSlice({requestType: null})
        setTimeout(() => setBrSlice({loading: false}), 1000)
        obj[reqKey] = omit(obj[reqKey], ['batteryBackupPercentage'])
      }

      if (isResponseHasUpdatedVeryLowSoc(resData)) {
        if (isIQGWScheduleSupportedSite()) {
          const resetBsSlice = {
            veryLowSoc: batteryDetails?.veryLowSoc || null,
            isBsInfoChanged: false,
          }
          setTimeout(() => setBsSlice({...resetBsSlice}), 1000)
        }

        setBsSlice({requestType: null})
        setTimeout(() => setBsSlice({loading: false}), 1000)
        obj[reqKey] = omit(obj[reqKey], ['veryLowSoc'])
      }
      
      // omit profile from config obj for battery update
      obj[reqKey] = omit(obj[reqKey], ['profile'])

      // Update Battery Profile Slice
      setBatteryData({...obj})
    }
    // Pending
    else if (data.status === -1) {
      toastType = TOAST_TYPE.INFO
      toastMessage = i18next.t('bp70')

      if (isResponseHasUpdatedChargeFromGrid(resData)) {
        setCbfgSlice({requestType: HTTPS_REQUEST_TYPE.API})

        if (source === HTTPS_REQUEST_TYPE.TASK) {
          setTimeout(() => setCbfgSlice({loading: false}), 1000)
        }
      }

      if (isResponseHasUpdatedDischargeToGrid(resData)) {
        setDbtgSlice({requestType: HTTPS_REQUEST_TYPE.API})

        if (source === HTTPS_REQUEST_TYPE.TASK) {
          setTimeout(() => setDbtgSlice({loading: false}), 1000)
        }
      }

      if (isResponseHasUpdatedBatteryBackupPercentage(resData)) {
        setBrSlice({requestType: HTTPS_REQUEST_TYPE.API})

        if (source === HTTPS_REQUEST_TYPE.TASK) {
          setTimeout(() => setBrSlice({loading: false}), 1000)
        }
      }

      if (isResponseHasUpdatedVeryLowSoc(resData)) {
        setBsSlice({requestType: HTTPS_REQUEST_TYPE.API})

        if (source === HTTPS_REQUEST_TYPE.TASK) {
          setTimeout(() => setBsSlice({loading: false}), 1000)
        }
      }

      // Update Battery Profile Slice
      obj[reqKey] = {...getBatteryProfileDetails()[reqKey], ...resData}
      obj[reqKey] = omit(obj[reqKey], ['systemTask'])
      setBatteryData({...obj})
    }

    // Show error dialog
    if (isActivePage(PAGE_NAME.BATTERY) && showRequestChangeErrorDialog({data})) {
      const reqData = {...data}
      reqData.redirectFrom = PAGE_NAME.BATTERY
      reqData.isGatewayNotReporting = isGatewayNotReporting()

      // We consider a profile change request if the profile in the MQTT data differs from the existing profile.
      const isProfileChangeRequest = getBatteryProfileDetails().profile !== reqData.profile

      showDialog(getRequestFailedAlertDialog({details: reqData, closeThisDialog, isProfileChangeRequest}))
    } else if (isActivePage(PAGE_NAME.BATTERY) && !(data.systemTask && data.status === -1)) {
      // Show Toast
      showToast({
        type: toastType,
        message: toastMessage,
        autoClose: 3000,
      })
    }
  }
}

function isResponseHasUpdatedChargeFromGrid(resData) {
  const batteryProfile = getBatteryProfileDetails()

  return (
    (_.isBoolean(resData.chargeFromGrid) && resData.chargeFromGrid !== batteryProfile.chargeFromGrid) ||
    (!_.isNil(resData.chargeBeginTime) && resData.chargeBeginTime !== batteryProfile.chargeBeginTime) ||
    (!_.isNil(resData.chargeEndTime) && resData.chargeEndTime !== batteryProfile.chargeEndTime) ||
    // Grid Schedule enable
    (!showNewCFG() &&
      !isSiteGridTied() &&
      !_.isNil(resData.chargeFromGridScheduleEnabled) &&
      resData.chargeFromGridScheduleEnabled !== batteryProfile.chargeFromGridScheduleEnabled) ||
    // cfgControl check
    (isIQGWScheduleSupportedSite() &&
      ((_.isBoolean(resData.cfgControl.locked) && resData.cfgControl.locked !== batteryProfile.cfgControl.locked) ||
        (_.isBoolean(resData.cfgControl.scheduleSupported) &&
          resData.cfgControl.scheduleSupported !== batteryProfile.cfgControl.scheduleSupported) ||
        (_.isBoolean(resData.cfgControl.forceScheduleSupported) &&
          resData.cfgControl.forceScheduleSupported !== batteryProfile.cfgControl.forceScheduleSupported)))
  )
}

// Discharge To Grid response change check
function isResponseHasUpdatedDischargeToGrid(resData) {
  const batteryProfile = getBatteryProfileDetails()
  return (
    (_.isBoolean(resData.dtgControl?.enabled) && resData.dtgControl?.enabled !== batteryProfile.dtgControl?.enabled) ||
    (!_.isNil(resData.dtgControl?.startTime) &&
      resData.dtgControl?.startTime !== batteryProfile.dtgControl?.startTime) ||
    (!_.isNil(resData.dtgControl?.endTime) && resData.dtgControl?.endTime !== batteryProfile.dtgControl?.endTime) ||
    (_.isBoolean(resData.dtgControl?.locked) && resData.dtgControl.locked !== batteryProfile.dtgControl.locked) ||
    (_.isBoolean(resData.dtgControl?.scheduleSupported) &&
      resData.dtgControl.scheduleSupported !== batteryProfile.dtgControl.scheduleSupported)
  )
}

export function isResponseHasUpdatedBatteryBackupPercentage(resData) {
  const batteryProfile = getBatteryProfileDetails()

  return resData.batteryBackupPercentage && resData.batteryBackupPercentage !== batteryProfile.batteryBackupPercentage
}

function isResponseHasUpdatedVeryLowSoc(resData) {
  const batteryProfile = getBatteryProfileDetails()

  return resData.veryLowSoc && resData.veryLowSoc !== batteryProfile.veryLowSoc
}
