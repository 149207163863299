/* eslint-disable fp/no-mutating-methods */
/* eslint-disable fp/no-mutation */
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Icon from 'lib/utils/icon'
import './styles.scss'

export default class EnergyType extends React.PureComponent {
  getAnimateIconContent = () => {
    const content = []

    if (this.props.animateIcon) {
      let counter = 3

      if (!isNaN(this.props.animateIcon)) {
        counter = this.props.animateIcon
      }

      for (let i = counter; i >= 0; i -= 1) {
        content.push(
          <div
            className="energy-type__circle"
            style={{animationDelay: `-${i}s`}}
            key={`energy-type__circle-${i}${new Date().valueOf()}`}
          />
        )
      }
    }
    return content
  }

  getClassName() {
    const classname = ['energy-type']

    if (this.props.className) {
      classname.push(`energy-${this.props.className.toLowerCase()}`)
    }

    if (this.props.animateIcon) {
      classname.push('energy-type__icon__has-animation')
    }

    return classname.join(' ')
  }

  renderDOM() {
    const {animateIcon, loadingValue, valueInfoCB, icon, value, unit, text, subTitle, valueId, textId, hasGenerator} =
      this.props
    const contentIconAnimation = this.getAnimateIconContent(animateIcon)

    return (
      <div className="energy-type__icon-with-details">
        {icon && (
          <div className={`energy-type__icon-with-details__icon ${hasGenerator ? 'has_generator' : ''}`}>
            <Icon src={icon} {...this.props} />
            {contentIconAnimation}
          </div>
        )}

        <div className="energy-type__icon-with-details__details" aria-label={valueId}>
          {loadingValue ? (
            <p className="energy-type__icon-with-details__details__value-loading">{loadingValue}</p>
          ) : (
            (!_.isEmpty(value) || valueInfoCB) && (
              <p id={valueId} aria-labelledby={valueId} className="energy-type__icon-with-details__details__value">
                {value}

                {unit && <span className="energy-type__icon-with-details__details__value__unit">{unit}</span>}

                {valueInfoCB && (
                  <span
                    className="energy-type__icon-with-details__details__value__value-info"
                    role="button"
                    tabIndex={0}
                    onClick={valueInfoCB}
                    onKeyDown={valueInfoCB}>
                    <Icon src="infoIcon" automationInfo={`${valueId}Info`} />
                  </span>
                )}
              </p>
            )
          )}

          {!_.isEmpty(subTitle) && (
            <p className="energy-type__icon-with-details__details__sub-title" id={valueId} aria-labelledby={valueId}>
              {subTitle}
            </p>
          )}

          {text && (
            <p aria-label={textId} className="energy-type__icon-with-details__details__name">
              <span id={textId} aria-labelledby={textId}>
                {text}
              </span>
            </p>
          )}
        </div>
      </div>
    )
  }

  renderInlineIconAndValue() {
    const {animateIcon, loadingValue, valueInfoCB, icon, value, unit, text, subTitle, valueId, textId} = this.props
    const contentIconAnimation = this.getAnimateIconContent(animateIcon)

    return (
      <div className="energy-type__icon-with-details">
        <div aria-label={valueId} className="energy-type__icon-with-details__icon-and-value">
          {icon && (
            <div className="energy-type__icon-with-details__icon-and-value__icon">
              <Icon src={icon} percent={value} />
              {contentIconAnimation}
            </div>
          )}

          {loadingValue ? (
            <p className="energy-type__icon-with-details__icon-and-value__value-loading">{loadingValue}</p>
          ) : (
            <p id={valueId} aria-labelledby={valueId} className="energy-type__icon-with-details__icon-and-value__value">
              {value}

              {unit && <span className="energy-type__icon-with-details__icon-and-value__value__unit">{unit}</span>}

              {valueInfoCB && (
                <span
                  className="energy-type__icon-with-details__icon-and-value__value__value-info"
                  role="button"
                  tabIndex={0}
                  onClick={valueInfoCB}
                  onKeyDown={valueInfoCB}>
                  <Icon src="infoIcon" automationInfo={`${valueId}Info`} />
                </span>
              )}
            </p>
          )}
        </div>

        {!_.isEmpty(subTitle) && <span className="energy-type__icon-with-details__sub-title">{subTitle}</span>}

        <div aria-label={textId} className="energy-type__icon-with-details__details">
          {text && (
            <p id={textId} aria-labelledby={textId} className="energy-type__icon-with-details__details__name">
              {text}
            </p>
          )}
        </div>
      </div>
    )
  }

  renderConditionalDOM() {
    if (this.props.inlineIconAndValue) {
      if (this.props.value !== 'Unknown') return this.renderInlineIconAndValue()
      return null
    }
    return this.renderDOM()
  }

  render() {
    const {onClick} = this.props
    const classname = this.getClassName()

    return (
      <div className={classname} onClick={onClick} role="document" tabIndex={0} onKeyDown={onClick}>
        {this.renderConditionalDOM()}
        {/* {inlineIconAndValue ? (value !== 'Unknown' ? this.renderInlineIconAndValue() : null) : this.renderDOM()}{' '} */}
        {/* constant UNKNOWN */}
      </div>
    )
  }
}

EnergyType.propTypes = {
  className: PropTypes.string,
  valueId: PropTypes.string,
  textId: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  unit: PropTypes.string,
  animateIcon: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  loadingValue: PropTypes.number,
  valueInfoCB: PropTypes.func,
  inlineIconAndValue: PropTypes.bool,
  subTitle: PropTypes.string,
  onClick: PropTypes.func,
  hasGenerator: PropTypes.bool,
  percent: PropTypes.number,
}

EnergyType.defaultProps = {
  className: '',
  valueId: '',
  textId: '',
  icon: null,
  text: null,
  unit: null,
  animateIcon: false,
  loadingValue: null,
  valueInfoCB: null,
  inlineIconAndValue: false,
  subTitle: '',
  onClick: () => {},
  hasGenerator: false,
  percent: 100,
}
